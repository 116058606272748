import React from 'react';

function HistoryItem({ item, index, dataLength, getBorderClass, getBulletStatus }) {
  return (
    <div className="row">
      <div className="col-3">
        <div className="step text-center">
          <div className={`border-step ${getBorderClass(index)} ${index === 0 ? 'border-step-first' : ''} ${index === dataLength ? 'border-step-last' : ''}`} />
          <img src={getBulletStatus(index)} />
        </div>
      </div>
      <div className="col-9 tarjetahistorial fondo-blanco padding shadow-10">
        <span className="texto-rojo">{item.Date}</span>
        <p className="font-bold">{item.Details}</p>
      </div>
    </div>
  )
}

export default HistoryItem;
